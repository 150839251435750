/*eslint-disable */

import request from "../../../Utils/curl"
import Utility from "../../../Utils/utility"

let analyticsFollower = {
  /**
   * analytics_followed_list
   */
  async analyticsFollowedList (context, orgObj, whereFilter = null) {
    Utility.showLoader = true
    Utility.loadingMsg = "Please wait...."
    try {
      let postData = new FormData()
      if (whereFilter) {
        postData.append("filter", JSON.stringify(whereFilter))
      }

      if (orgObj) {
        for (let key of Object.keys(orgObj)) {
          if (orgObj[key]) {
            postData.append(key, orgObj[key])
          }
        }
      }

      return await request.curl(context, "analytics_followed_list", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at analytics_followed_list() and Exception:", err.message)
    }
    finally {
      Utility.showLoader = false
    }
  },
  /**
 * analyticsFollowerCount
 */
  async analyticsFollowerCount (context, filter = {}) {
    try {
      let postData = new FormData()

      if (Object.keys(filter).length > 0) {
        postData.append("filter", JSON.stringify(filter))
      }

      return await request.curl(context, "analytics_follower_count", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at analyticsFollowerCount() and Exception:", err.message)
    }
  }
}

export {
  analyticsFollower
}
